.buttonsPanel {
  display: flex;
  align-items: center;
  gap: 20px;
}

.buttonsPanel.right {
  justify-content: end;
}

@media (max-width: 767px) {
  .buttonsPanel {
    flex-direction: column;
  }

  .buttonsPanel button,
  .buttonsPanel a,
  .buttonsPanel input[type="submit"],
  .buttonsPanel input [type="button"] {
    display: block;
    width: 100%;
  }
}
