
.addressCardsContainer {
  margin-top: 15px;
  display: flex;
  gap: 15px 30px;
  flex-flow: wrap;
}

/*
.addressCardsContainer {
  margin-top: 15px;
  grid-auto-columns: max-content;
  column-gap: 30px;
  row-gap: 15px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
  align-items: center;
}
*/