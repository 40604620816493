.label {
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: 400;
  text-transform: uppercase;
}

.pg-1 {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  text-align: justify;
  color: #383838;
}

.pg-2 {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 185% */

  /* Dark Grey */

  color: #5c5e63;
}

.pg-4 {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 24px;
  color: var(--purple-500);
}

.pg-5 {
  left: 484px;
  top: 162px;

  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  /* identical to box height */

  color: var(--purple-500);
}

.pg-6 {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 37px;
  padding-left: 10px;
  /* identical to box height */

  color: var(--purple-500);
}

.ta-j {
  text-align: justify;
}

.fl {
  float: left;
}

.notification-logo {
  width: 60px;
}

.ca-subheading {
  height: 18px;
  left: 569px;
  top: 405px;

  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;

  /* Primary Color */

  color: var(--purple-500);
}

.color-red {
  color: red !important;
}

/* .bg-success {
  background-color: #333366 !important;
} */

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em;
  color: var(--secondary-500);
  background: transparent
    url('data:image/svg + xml;charset=utf-8,%3Csvgxmlns="http://www.w3.org/2000/svg"viewBox="0 0 16 16"fill="%23000"%3E%3Cpathd="M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z"/%3E%3C/svg%3E')
    50%/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}

.reg-account-type-text{
  font-size: 1.0rem;
  color: #383838;
}

.Calendar {
  box-shadow: none !important;
  width: 100% !important;
}

.Calendar__day { height: 45px;}

@media (max-width: 767px) {
  .pg-5{
    font-size: 20px;
  }
}