.container div.row {
  padding: 15px 20px;
  border: 1px solid var(--purple-100);
  border-bottom: 0;
}

.container div.row:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.container div.row:last-child {
  border-bottom: 1px solid var(--purple-100);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.container .row p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
}

.container .heading {
  background: var(--purple-50);
}

.container .heading p {
  margin-bottom: 0;
}

.container .heading p:first-child {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--purple-500);
}

.container .heading p:last-child {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  margin-top: 5px;
}
