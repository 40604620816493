.cardDiv {
  width: 355px;
  height: 210px;
  background: #EBEBF0;
  border-radius: 15px;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
}

.cardDiv .actionBar {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.cardDiv .actionBar .info {
  flex: 2;
  align-items: flex-start;
}

.cardDiv .actionBar .info .status {
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 16px;
  gap: 10px;
  width: 74px;
  height: 32px;
  background: #ffffff;
  border: 1px solid var(--purple-500);
  border-radius: 30px;
  font-weight: 700;
  font-size: 10px;
  line-height: 32px;
  letter-spacing: 0.1em;
  color: var(--purple-500);
}

.cardDiv .actionBar .actionButton {
  flex: 1;
  text-align: end;
}

.cardDiv .actionBar .actionButton .remove {
  cursor: pointer;
}

.cardDiv .heading {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: var(--purple-500);
  margin-top: 10px;
}

.cardDiv .body{
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #3f3844;
    text-transform: capitalize;
}

.location-img{
  position: absolute;
  left: 51.53%;
  right: 45.18%;
  top: 50.58%;
  bottom: 44.2%;
}


