.dropdown {
  position: relative;
}

.dropdown .menu {
  position: absolute;
  top: 30px;
  left: -21rem;
  display: none;
  background: #fff;
  z-index: 999;
  list-style: none;
  box-shadow: 0 2px 5px rgb(0 0 0 / 28%);
  width: 23rem;
}

.dropdown .menu.show {
  display: block;
}

.main .container:first-child {
  padding: 15px 20px 0px 20px;
}

.dropdown .icon {
  position: relative;
}

.dropdown .icon span {
  position: absolute;
  top: -2px;
  right: -6px;
  width: 15px;
  height: 15px;
  background: #ec1212;
  border-radius: 50%;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.notificationArea {
  max-height: 320px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0px 20px;
}

.notificationArea .icons {
  gap: 10px;
}

.notificationArea p {
  margin-bottom: 0;
}

.notificationArea .bar {
  padding: 10px 0;
}

.notificationArea .bar.messageNotificationRow {
  padding: 10px 0;
  margin-bottom: 10px;
}

.notificationArea .bar:not(:last-child) {
  border-bottom: 1px solid var(--text-grey-50);
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .main .container h6,
  .main .container a {
    font-size: 15px;
  }

  .main .container p {
    font-size: 15px;
  }

  .dropdown .menu {
    width: 50vw;
    left: -45vw;
  }
}

@media (max-width: 650px) {
  .dropdown .menu {
    width: 60vw;
    left: -55vw;
  }
}

@media (max-width: 550px) {
  .dropdown .menu {
    width: 70vw;
    left: -65vw;
  }
}

@media (max-width: 450px) {
  .dropdown .menu {
    width: 80vw;
    left: -70vw;
  }
}

@media (max-width: 390px) {
  .dropdown .menu {
    width: 90vw;
    left: -70vw;
  }
}

@media (max-width: 350px) {
  .dropdown .menu {
    width: 92vw;
    left: -70vw;
  }
}

@media (max-width: 340px) {
  .dropdown .menu {
    width: 92vw;
    left: -69vw;
  }
}

@media (max-width: 340px) {
  .dropdown .menu {
    width: 92vw;
    left: -68vw;
  }
}

.messageNotificationRow {
  cursor: pointer;
}

.messageNotificationRow:hover,
.messageNotificationRow:focus,
.messageNotificationRow:active {
  background: var(--purple-50);
}

.readIcon {
  padding-top: 6px;
}

.subject {
  color: var(--purple-500);
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 5px !important;
}

.sender {
  color: var(--text-grey-300);
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 5px !important;
}
