.bar {
  width: 100%;
  display: block;
  height: 1px;
  background: #e8e8e8;
}

.progress {
  width: 310px;
  height: 1px;
  background: var(--secondary-500);
}

.progressSecondary {
  width: 310px;
  height: 1px;
  background: var(--purple-500);
}
