.box {
  width: 204px;
  height: 203px;
  background: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.box .image {
  width: 190px;
  height: 107px;
  border-radius: 25px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.box .name {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--purple-500);
  margin-top: 25px;
}

@media (max-width: 767px) {
  .box {
    width: auto;
  }
}
