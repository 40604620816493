.picture-box {
  text-align: center;
  max-width: 170px;
  min-height: 150px;
  max-height: 180px;
  display: flex;
  flex-direction: column;
}

.picture-box .picture-box-span {
  flex: 1;
}

.picture-box img.avatar {
  max-width: 170px;
  min-width: 100px;
  max-height: 140px;
}

.dragPanel {
  background: var(--text-grey-50);
  border: 2px dashed var(--purple-200);
  border-radius: 6px;
}

.dragPanel:hover,
.dragPanel:active,
.dragPanel:focus,
.dropActive {
  background: var(--purple-100);
}

.uploadedRow {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 20px;
}

.uploadedRow .tile {
  height: 140px;
  width: 210px;
  border-radius: 6px 6px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.uploadedRow .tilePdf {
  height: 140px;
  width: 150px;
  border-radius: 6px 6px;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  border: 1px solid var(--text-grey-100);
  display: flex;
  align-items: end;
}

.uploadedRow .tilePdf .fileName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  color: var(--text-grey-100);
  padding: 2px 10px;
}

.uploadedRow .tile img,
.uploadedRow .tilePdf img {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
