.picture-box {
  text-align: center;
  max-width: 170px;
  min-height: 150px;
  max-height: 180px;
  display: flex;
  flex-direction: column;
}

.picture-box .picture-box-span {
  flex: 1;
}

.picture-box img.avatar {
  max-width: 170px;
  min-width: 100px;
  max-height: 140px;
}

.updbtn {
  display: inline-flex;
  font-size: 18px;
  margin-top: 20px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.updbtn img {
  width: 18px;
  max-height: 150px;
  margin-right: 10px;
}
