.breadcrumb {
  display: flex;
  gap: 10px;
  align-items: center;
}

.breadcrumb .item {
  font-weight: 400;
  font-size: 17px;
  line-height: 17px;
  color: var(--secondary-500);
  cursor: pointer;
}

.breadcrumb .item.noPointer {
  cursor: auto !important;
}

.breadcrumb div:last-of-type {
  color: #333366;
}

.breadcrumb .caret:last-child {
  display: none;
}
