.modal {
  position: fixed;
  top: 30vh;
  left: 10%;
  width: 80%;
  z-index: 10000;
  overflow: hidden;
}

.header {
  padding: 0.7rem 15px;
}

.content {
  padding: 1rem;
  padding-bottom: 0;
}

.content .imgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
}

.content .imgContainer .img {
  width: 100px;
}

.content h2 {
  text-align: center;
}

.content p {
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  margin-top: 15px;
}

.actions {
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.actions button {
  margin: 10px;
}

@media (min-width: 768px) {
  .modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}
