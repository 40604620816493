.cardDiv {
  width: 355px;
  height: 193px;
  background: #c1e2f5;
  border-radius: 15px;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 15px 20px;
}

.cardDiv .actionBar {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.cardDiv .actionBar .info {
  flex: 1;
  align-items: flex-start;
}

.cardDiv .actionBar .info .status {
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 16px;
  gap: 10px;
  width: 74px;
  height: 32px;
  background: #ffffff;
  border: 1px solid var(--purple-500);
  border-radius: 30px;
  font-weight: 700;
  font-size: 10px;
  line-height: 32px;
  letter-spacing: 0.1em;
  color: var(--purple-500);
}

.cardDiv .actionBar .actionButton {
  flex: 1;
  text-align: end;
}

.cardDiv .actionBar .actionButton .remove {
  cursor: pointer;
}

.cardDiv .heading {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: var(--purple-500);
}

.cardDiv .numberBar {
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #5c5e63;
}

.cardDiv .validity {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: var(--purple-500);
}

.cardDiv .validity .date {
  color: #5c5e63;
  padding-left: 5px;
  font-weight: 600;
}
