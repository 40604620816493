.bannerImg {
  background-image: url("../../assets/images/banner.svg");
}

.textContainer {
  align-self: center;
  justify-self: center;
  text-align: center;
  width: 500px;
}

.textContainer .heading {
  font-weight: 700;
  font-size: 32px;
  line-height: 37px;
  text-transform: capitalize;
}

.textContainer .para {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  margin-top: 30px;
  color: var(--purple-500);
}

.textContainer .buttons {
  display: inline-flex;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .textContainer {
    width: 60%;
  }

  .textContainer .heading {
    font-size: 18px;
    line-height: 22px;
    text-align: left;
  }

  .textContainer .para {
    font-size: 14px;
    line-height: 26px;
    margin: 0px;
    text-align: left;
  }
}

@media (max-width: 575px) {
  .bannerImg {
    background-image: url("../../assets/images/banner-mb.svg");
  }

  .textContainer {
    padding-left: 10%;
  }

  .textContainer .heading {
    font-size: 15px;
    line-height: 20px;
  }

  .textContainer .para {
    line-height: 22px;
  }
}

@media (max-width: 360px) {
  .textContainer .buttons {
    flex-direction: column-reverse;
  }

  .textContainer .buttons a {
    display: block;
  }
}
