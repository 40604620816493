.mainPanel {
  height: 427px;
  box-shadow: 0px 2px 5px rgba(36, 54, 101, 0.15);
  border-radius: 11px;
  display: flex;
  flex-direction: column;
}

.mainPanel .blockOne {
  flex: 1;
}

.mainPanel .blockTwo {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.mainPanel .imagePanel {
  width: 100%;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 11px 11px 0px 0px;
}

.mainPanel .textContainer {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.mainPanel .textContainer .auctionType p {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--text-grey-300);
  margin-bottom: 0;
  text-transform: capitalize;
}

.mainPanel .textContainer .headingPanel {
  margin: 10px 0;
}

.mainPanel .textContainer .headingPanel h2 {
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: var(--purple-500);
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mainPanel .textContainer .descPanel {
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--text-grey-500);
  margin-bottom: 10px;
}

.mainPanel .textContainer .timePanel {
  display: inline-flex;
  flex: 1;
  gap: 10px;
  margin-bottom: 10px;
}

.mainPanel .textContainer .timePanel img {
  width: 12px;
  height: 12px;
}

.mainPanel .textContainer .timePanel span:first-child {
  justify-self: center;
  align-self: center;
}

.mainPanel .textContainer .timePanel span:last-child {
  flex: 1;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: var(--text-grey-500);
  justify-self: center;
  align-self: center;
}

.mainPanel .textContainer .lot {
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  color: var(--text-grey-300);
}

.mainPanel .line {
  width: 100%;
  height: 1px;
  background: var(--text-grey-50);
}

.mainPanel .blockTwo .pricePanel {
  font-weight: 400;
  font-size: 21px;
  line-height: 24px;
  color: var(--text-grey-500);
}

.mainPanel .blockTwo .pricePanel span {
  color: var(--purple-500);
  font-weight: 700;
}

@media (max-width: 767px) {
  .mainPanel .textContainer .headingPanel h2 {
    font-size: 16px;
  }

  .mainPanel .blockTwo .pricePanel {
    font-size: 18px;
    line-height: 21px;
  }
}

@media (max-width: 575px) {
  .mainPanel .textContainer .auctionType p,
  .mainPanel .textContainer .descPanel {
    font-size: 14px;
    line-height: 18px;
  }
}
