.container {
  display: flex;
  gap: 10px;
  align-items: flex-start;
}

.container img {
  width: 15px;
  margin-top: 4px;
}

.container p {
  margin-bottom: 0;
  flex: 1;
  color: var(--text-grey-500);
}
