.heading {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: var(--purple-500);
}

.heading span {
  color: var(--purple-200);
}

.viewMessagePanel {
  background-color: var(--text-grey-50);
  max-height: 500px;
  overflow: auto;
}

.subjectPanel .row {
  margin-bottom: 2px;
  padding: 10px 0px;
  color: var(--purple-500);
  align-items: center;
}

@media (max-width: 991px) {
  .subjectPanel .row {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
  }

  .subjectPanel .row .icon {
    display: none;
  }
}

.subjectPanel .subject {
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
}

.subjectPanel .date {
  font-weight: 400;
  font-size: 13px;
  line-height: 27px;
}

.recipientPanel .row {
  margin-bottom: 2px;
  color: var(--purple-500);
  align-items: center;
  font-weight: 400;
  font-size: 13px;
  line-height: 27px;
}
