.actionTypeTitle {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #86888d;
}

.actionTitle {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 37px;
  color: #333366;
}

.actionBiddingLable {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 24px;
  color: #86888d;
}

.actionBiddingValue {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  color: #158d63;
}

.clockIcon {
  height: 13px;
  width: 13px;
}

.auctionLabelEnd {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #8e8f94;
}

.auctionLocationLabel {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: #86888d;
}

.auctionLocationValues {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: #333366;
}

.sellerName {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  text-decoration-line: underline;
  color: #339966;
}

.sellerOtherAuction {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #656069;
}

.availableBids {
  flex-direction: row;
  align-items: center;
}

.availableBidValue {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 24px;
  text-align: center;
  color: #333366;
}

.infoTableLabel {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #86888d;
}

.infoTableValue {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #333366;
}

.infoTableValueGreen {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #158d63;
  border: none;
  background: none;
  padding: 0px;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 37px;
  color: var(--purple-500);
}

@media (max-width: 767px) {
  .title {
    font-size: 18px !important;
    line-height: 20px !important;
  }
}

.description {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #5c5e63;
}

.questionSubOption {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  color: #86888d;
}

.additionalInfoListItem {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #86888d;
}

.additionalInfoListItemValue {
  color: #333366;
}

.question {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.answer {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.timestamp {
  font-weight: 600;
  font-size: 16px;
}

.question:before {
  content: "Q. ";
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 37px;
  color: #243665;
}

.answer:before {
  content: "A. ";
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 37px;
  color: #243665;
}

.infoTableLabelBlue {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
  color: #333366;
}

.inspectionParagraph {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #3f3844;
}

.paymentParagraph {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #3f3844;
}

.infoTableLabelRed {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #fa0d0d;
}

.infoTableLabelGreen {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #1b6e48;
}

.timerElement {
  background: #86888d;
  padding: 5px;
  border-radius: 5px;
  color: #fff;
  font-family: "Helvetica";
  font-size: 20px;
  min-width: 60px;
  display: block;
  max-width: 100px;
  text-align: center;
  margin: 3px;
}

.timerP {
  display: flex;
}

.auctionLocationGreen {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: #339966;
}
