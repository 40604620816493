.banner {
  height: 180px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 11px;
  display: flex;
  align-items: center;
  column-gap: 80px;
  background-image: url("../../assets/images/small-banner.svg");
}

.banner .sectionText {
  padding-left: 60px;
}

.banner .sectionText .heading {
  font-weight: 700;
  font-size: 32px;
  line-height: 37px;
  color: var(--secondary-500);
}

.banner .sectionText .description {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
  margin-bottom: 10px;
}

.banner .sectionButton {
  flex: 1;
  justify-self: flex-start;
}

@media (max-width: 991px) {
  .banner {
    column-gap: 60px;
  }

  .banner .sectionText .heading {
    font-size: 25px;
    line-height: 30px;
  }

  .banner .sectionText .description {
    font-size: 21px;
    line-height: 26px;
  }
}

@media (max-width: 767px) {
  .banner {
    background-image: url("../../assets/images/small-banner-mb.svg");
    height: 260px;
    flex-direction: column;
    row-gap: 30px;
    align-items: flex-start;
    justify-content: center;
  }

  .banner .sectionText {
    flex: 0;
    padding-left: 10%;
  }

  .banner .sectionButton {
    flex: 0;
    padding-left: 10%;
  }

  .banner .sectionText .description {
    font-size: 14px;
    line-height: 18px;
  }

  .banner .sectionText .heading {
    font-size: 23px;
    line-height: 26px;
    color: #fff;
  }
}

@media (max-width: 575px) {
  .banner .sectionText .description {
    font-size: 12px;
    line-height: 14px;
  }

  .banner .sectionText .heading {
    font-size: 20px;
    line-height: 23px;
  }
}
