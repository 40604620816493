@media (max-width: 767px) {
  .heading {
    font-size: 18px !important;
    line-height: 20px !important;
  }
}

@media (max-width: 575px) {
  .heading {
    font-size: 16px !important;
    line-height: 18px !important;
  }
}
