.uploadedRow {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 20px;
}

.uploadedRow .tile {
  height: 140px;
  width: 130px;
  border-radius: 6px 6px;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  border: 1px solid var(--text-grey-100);
  display: flex;
  align-items: end;
  text-align: center;
}

.uploadedRow a.tile {
  height: 100%;
  border: none;
}

.uploadedRow .tile .fileName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  color: var(--text-grey-100);
  padding: 2px 10px;
  width: 128px;
}

.uploadedRow .tile img {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
