.title {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 37px;
  color: var(--purple-500);
}

.auctionsContainer {
  margin-top: 15px;
  grid-auto-columns: max-content;
  column-gap: 30px;
  row-gap: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
  align-items: center;
  /*justify-items: center;*/
}

@media (min-width: 1480px) {
  .auctionsContainer {
    column-gap: 140px;
  }
}

.viewAll {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}

@media (max-width: 767px) {
  .title {
    font-size: 18px !important;
    line-height: 20px !important;
  }

  .viewAll {
    font-size: 14px;
  }
}

@media (max-width: 575px) {
  .title {
    font-size: 16px !important;
    line-height: 18px !important;
  }

  .viewAll {
    font-size: 12px;
  }
}
