.heading {
  font-size: 24px;
  line-height: 28px;
}

.link {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: var(--secondary-500);
}

.link span {
  color: #8e8f94;
}

.link:hover,
.link:active {
  color: var(--purple-500);
}
