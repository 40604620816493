.main {
}

.main .tableContainer,
.main .smallTableContainer {
  padding: 10px 0px;
}

.main .tableContainer table tbody {
  background: var(--purple-50);
}

.main .tableContainer table thead tr th {
  border-bottom: none;
  font-size: 13px;
  font-weight: 400;
  vertical-align: middle;
  border: none;
}

.main .tableContainer table tbody tr td {
  vertical-align: middle;
}

.main .tableContainer input[type="checkbox"],
.main .smallTableContainer input[type="checkbox"] {
  accent-color: var(--secondary-500);
}

.main .tableContainer table tbody tr td {
  font-size: 13px;
  font-weight: 700;
}

.main .tableContainer table tbody tr td .productLabel,
.main .smallTableContainer .productLabel {
  font-size: 14px;
  font-weight: 700;
  color: var(--purple-500);
  display: block;
}

.main .tableContainer table tbody tr td .lot,
.main .smallTableContainer .lot {
  font-size: 12px;
  font-weight: 400;
  color: var(--text-grey-400);
  display: block;
}

.main .tableContainer table tfoot {
  background: var(--purple-200);
}

.main .tableContainer table tfoot tr th {
  border-bottom: none;
  font-size: 13px;
  font-weight: 700;
  vertical-align: middle;
  border: none;
  color: #fff;
}

.main .smallTableContainer .header {
  display: flex;
  align-items: center;
  padding: 5px 0 15px 0;
}

.main .smallTableContainer .panel .block {
  background: var(--purple-50);
  display: flex;
  align-items: center;
  padding: 15px 0px;
}

.main .smallTableContainer .panel .block.total {
  background: var(--purple-200);
}

.main .smallTableContainer .panel .blockPanel:not(:first-child) {
  border-top: 10px solid var(--purple-50);
  margin-top: 5px;
}

.main .smallTableContainer .panel .block .item:not(:first-child) {
  margin-top: 15px;
}

.main .smallTableContainer .panel .block .selection,
.main .smallTableContainer .header .selection {
  margin: 0 15px;
  align-self: flex-start;
}

.main .smallTableContainer .panel .block .label {
  font-size: 13px;
  font-weight: 400;
  color: var(--text-grey-400);
}

.main .smallTableContainer .panel .block .value {
  font-size: 14px;
  font-weight: 700;
  color: var(--text-grey-400);
}

.main .smallTableContainer .panel .block .totalRow {
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;
  padding: 0 30px 0 0;
}

.main .smallTableContainer .panel .block .totalRow .label,
.main .smallTableContainer .panel .block .totalRow .value {
  color: #fff;
}

.main .smallTableContainer {
  display: none;
}

@media (max-width: 1199px) {
  .main .tableContainer {
    display: none;
  }

  .main .smallTableContainer {
    display: block;
  }
}
