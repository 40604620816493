.uploadedRow {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 20px;
}

.uploadedRow .tile {
  height: 140px;
  width: 210px;
  border-radius: 6px 6px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  position: relative;
}

.uploadedRow .tile img {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
