.box {
  background: var(--secondary-50);
  border: 1px solid var(--secondary-500);
  border-radius: 6px;
  padding: 20px 25px;
  text-align: justify;
}

.box p {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #5c5e63;
}
