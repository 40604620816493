.sellerPicture {
  height: 96px;
  width: 96px;
}

.sellerName {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;

  /* Foundation /primary/primary-500 */

  color: #333366;
  text-transform: capitalize;
}

.sellerpg {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;

  /* Foundation /primary/primary-500 */

  color: #333366;
}

.sellerLine {
  width: 400px;
  height: 1px;
  background: #c0c0d0;
}

.sellerTimeLink {
  height: 24px;

  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 100% */

  /* Foundation /primary/primary-500 */

  color: #333366;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.bannerImg {
  background-image: url("../../assets/images/demo_meeting_slider_image.svg");
  min-height: 280px;
}

.bannerTextContainer {
  align-self: center;
  justify-self: center;
  /* text-align: center; */
  /* width: 500px; */
}

.bannerTextContainer .heading {
  font-weight: 700;
  font-size: 32px;
  line-height: 37px;
  text-transform: capitalize;
}

.bannerTextContainer .para {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  /* text-align: center; */
  margin-top: 30px;
  color: var(--purple-500);
}

.demoMeetingImg {
  background-image: url("../../assets/images/demo_meeting_img.svg");
  height: 386px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  background-origin: content-box;
}

.textContainer {
  align-self: center;
  justify-self: center;
  text-align: center;
  color: #000000;
}

.textContainer .heading {
  font-weight: 700;
  font-size: 32px;
  line-height: 37px;
  text-transform: capitalize;
}

.textContainer .para {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  margin-top: 30px;
  color: var(--purple-500);
}

.heading {
  font-weight: 700;
  font-size: 32px;
  line-height: 37px;
  text-transform: capitalize;
}

.heading2 {
  font-weight: 700;
  font-size: 18px;
  line-height: 37px;
  text-transform: capitalize;
}

.para {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  margin-top: 30px;
  color: var(--purple-500);
}

.stepsCard {
  width: 350px;
  height: 420px;
  background: #ebf5f0;
  border-radius: 30px;
}

.stepsCard .stepNo {
  height: 60px;

  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 80px;

  text-align: right;
  text-transform: capitalize;

  /* Foundation /Secondary/secondary-300 */

  color: #76bb98;
}

.learnMoreContainer {
  width: 1111px;
  height: 375px;
  left: 280px;
  top: 2236px;

  background: linear-gradient(
    102.16deg,
    rgba(157, 226, 176, 0.31) -13.48%,
    rgba(236, 179, 94, 0.17) 96.45%
  );
}

.timeSlotContainer {
  height: 400px;
  overflow: scroll;
}

.timeSlot {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  gap: 10px;
  background: #ffffff;
  border: 1px solid var(--secondary-500);
  border-radius: 6px;
  margin-bottom: 10px;
  margin-right: 8px;
  cursor: pointer;
}

.timeSlot .timeText {
  height: 28px;

  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  /* identical to box height */

  text-align: center;

  /* Foundation /Secondary/secondary-500 */

  color: #339966;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.selectedSlot {
  background: #ebf5f0;
  /* Foundation /Secondary/secondary-500 */

  border: 2px solid var(--secondary-500);
  border-radius: 6px;
}

.customCalendar {
  /* box-shadow: 0 1em 3em rgba(156, 136, 255,0.2); */
  background-color: #ffffff;
}

.customTodayDay {
  color: #000000 !important;
  border: 2px solid var(--secondary-500) !important;
  /* background: #339966; */
}

.customTodayDay::after {
  visibility: hidden; /* hide small border under the text */
}

@media (max-width: 767px) {
  .learnMoreContainer {
    height: 540px;
  }

  .textContainer .para {
    padding: 15px;
  }

}