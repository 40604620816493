.main {
  background: var(--purple-50);
}
.main > .header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--purple-500);
  font-weight: 700;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  padding: 10px 20px;
}

.main > .header > img {
  transform: rotate(180deg);
}

.main > .header.close > img {
  transform: rotate(0deg);
}

.main .tableContainer,
.main .smallTableContainer {
  padding: 10px 10px;
}

.main .tableContainer table thead tr th {
  border-bottom: none;
  font-size: 13px;
  font-weight: 400;
  vertical-align: middle;
}

.main .tableContainer table tbody tr {
  background: #fff;
}

.main .tableContainer table tbody tr td {
  border-top: 10px solid var(--purple-50);
  vertical-align: middle;
}

.main .tableContainer table tbody tr td a:not(:first-child) {
  margin-top: 10px;
}

.main .tableContainer input[type="checkbox"],
.main .smallTableContainer input[type="checkbox"] {
  accent-color: var(--secondary-500);
}

.main .tableContainer table tbody tr td {
  font-size: 13px;
  font-weight: 700;
}

.main .tableContainer table tbody tr td .productLabel,
.main .smallTableContainer .productLabel,
.main .tableContainer table tbody tr td .productLabel a,
.main .smallTableContainer .productLabel a {
  font-size: 14px;
  font-weight: 700;
  color: var(--purple-500);
  display: block;
}

.main .tableContainer table tbody tr td .lot,
.main .smallTableContainer .lot,
.main .tableContainer table tbody tr td .lot a,
.main .smallTableContainer .lot a {
  font-size: 12px;
  font-weight: 400;
  color: var(--text-grey-400);
  display: block;
}

.main .tableContainer table tbody tr a.button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.main .tableContainer table tbody tr a.button img {
  max-width: 15px;
  max-height: 15px;
}

.main .tableContainer table tbody tr a.button:hover,
.main .tableContainer table tbody tr a.button:active,
.main .tableContainer table tbody tr a.button:focus {
  color: var(--secondary-500);
  background: #fff;
}

.main .smallTableContainer .header {
  display: flex;
  align-items: center;
  padding: 5px 0 15px 0;
}

.main .smallTableContainer .panel .block {
  background: #fff;
  display: flex;
  align-items: center;
  padding: 15px 0px;
}

.main .smallTableContainer .panel .block .blockText {
  flex: 1;
}

.main .smallTableContainer .panel .block.bordered {
  border-top: 1px solid var(--purple-50);
}

.main .smallTableContainer .panel .blockPanel:not(:first-child) {
  border-top: 10px solid var(--purple-50);
}

.main .smallTableContainer .panel .block .item:not(:first-child) {
  margin-top: 15px;
}

.main .smallTableContainer .panel .block .selection,
.main .smallTableContainer .header .selection {
  margin: 0 15px;
  align-self: flex-start;
}

.main .smallTableContainer .panel .block .label {
  font-size: 13px;
  font-weight: 400;
  color: var(--text-grey-400);
}

.main .smallTableContainer .panel .block .value {
  font-size: 14px;
  font-weight: 700;
  color: var(--text-grey-400);
}

.main .smallTableContainer .panel .block .totalRow {
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;
}

.main .smallTableContainer .panel .block .totalRow .buttons {
  display: inline-flex;
  gap: 10px;
  padding: 0px 10px;
}

.main .smallTableContainer .panel .block .totalRow .buttons a {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.main .smallTableContainer .panel .block .totalRow .buttons a img {
  max-width: 15px;
  max-height: 25px;
}

.main .smallTableContainer {
  display: none;
}

@media (max-width: 1199px) {
  .main .tableContainer {
    display: none;
  }

  .main .smallTableContainer {
    display: block;
  }
}

.badge {
  font-size: 0.9rem;
  white-space: break-spaces;
}

.uLabel {
  color: var(--text-grey-400);
  font-size: 0.7rem;
  white-space: break-spaces;
}

.uLabel.highlight {
  color: var(--danger);
}

.notificationAlertCell {
  border: 0 !important;
  padding-top: 0 !important;
  color: var(--purple-400);
}
