.row {
  background: var(--purple-100);
  margin-bottom: 2px;
  padding: 10px 0px;
  color: var(--purple-500);
  cursor: pointer;
  align-items: center;
}

.row.read {
  background: var(--text-grey-50);
}

.row:hover,
.row:active {
  background: var(--text-grey-100) !important;
}

@media (max-width: 991px) {
  .row {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
  }

  .row .icon {
    display: none;
  }
}

.icon {
}

.receipant {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

.subject {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.date {
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
}
