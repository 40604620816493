.searchContainer {
  position: relative;
}

.resultsContainer {
  position: absolute;
  width: 100%;
  background: #fff;
  z-index: 2;
  box-shadow: 2px 3px 12px -10px rgb(0 0 0 / 80%);
  border-radius: 0 0 6px 6px;
}

.resultsContainer ul {
  margin: 0;
  list-style: none;
  padding-top: 5px;
  padding-bottom: 5px;
}

.resultsContainer ul li {
  padding: 6px 20px;
  cursor: pointer;
  font-size: 13px;
}

.resultsContainer ul li:hover,
.resultsContainer ul li:active,
.resultsContainer ul li:focus {
  background: var(--text-grey-50);
}

.resultsContainer .searchResult:not(:last-child) {
  margin-bottom: 10px;
  border-bottom: 1px solid var(--text-grey-50);
}

.resultsContainer .searchResult h5 {
  padding: 0px 20px;
  margin: 0;
}

.resultsContainer .auctions ul li {
  display: flex;
  column-gap: 20px;
}

.resultsContainer .auctionImage {
  width: 140px;
  height: 90px;
  background-repeat: no-repeat;
  background-size: cover;
}

.resultsContainer .auctionTextContainer {
  flex: 1;
}

.resultsContainer .auctionTextContainer .type {
  color: var(--text-grey-300);
  margin-bottom: 10px;
  text-transform: capitalize;
}

.resultsContainer .auctionTextContainer .title {
  color: var(--purple-500);
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
  max-width: 270px;
}

.resultsContainer .auctionTextContainer .price {
  color: var(--text-grey-300);
}

.resultsContainer .auctionTextContainer .price span {
  color: var(--purple-500);
}

.searchContainer .resultsContainer {
  display: none;
}

.searchContainer:hover .resultsContainer,
.searchContainer:focus .resultsContainer,
.searchContainer:focus-within .resultsContainer {
  display: block;
}
